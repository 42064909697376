import { Button } from "primereact/button";
import Snowfall from "react-snowfall";
import "./Result.css";
import { useEffect, useState } from "react";
import img from "../Image/treno.png";
import AuthenticationService from "../Service/AuthenticationService";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../Router/Routes";

const Result = () => {
  const [userInformations, setUserInformations] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (AuthenticationService.tirage === null) navigate(PATHS.HOME);
    setUserInformations(AuthenticationService.tirage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="App relative overflow-hidden ">
      <Snowfall />
      <div className="">
        <div
          className="absolute top-16 animate-sleigh z-20"
          style={{
            width: "500px",
            height: "auto",
          }}
        >
          <img
            src={img}
            alt="Traîneau du Père Noël"
            className="w-full h-auto"
          />
        </div>
        {/* Card de révélation */}
        <div className="flex min-h-screen items-center justify-center app-container">
          <div className="bg-white rounded-lg shadow-lg p-8 md:w-1/2 text-center relative z-10">
            <h1 className="text-3xl font-bold text-gray-800 mb-4">
              🎄Félicitations !🎄
            </h1>
            <p className="text-xl text-gray-600">Vous avez tiré :</p>
            <div className="bg-yellow-200 rounded-lg mt-4 p-6">
              <h2 className="text-4xl font-extrabold text-yellow-700">
                {userInformations}
              </h2>
            </div>
            <Button
              label="Déconnexion"
              className="w-60 mt-10 h-9"
              onClick={() => {
                AuthenticationService.logout();
                navigate(PATHS.HOME);
              }}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
