import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL || "";

export const login = async (email: string, password: string) => {
  console.log(email);
  const user = await axios.post(`${apiUrl}/login`, {
    name: email,
    password,
  });
  return user;
};
export const signOut = async (email: string, token: string) => {};

export const listDrawPersons = async () => {
  const listDrawPersons = await axios.get(`${apiUrl}/listUser`);
  return listDrawPersons.data;
};

export const draw = async (name: string) => {
  const drawnPerson = await axios.post(`${apiUrl}/tirage`, {
    name: name,
  });
  return drawnPerson.data.drawnPerson;
};
