import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { PATHS } from "./Routes";
import Login from "../Components/Login";
import Draw from "../Components/Draw";
import Result from "../Components/Result";
import PrivateRoute from "./PrivateRoute";
import Admin from "../Components/Admin";
import PrivateAdminRoad from "./PrivateAdminRoad";

const Content: React.FC<{}> = () => {
  return (
    <Routes>
      <Route path={PATHS.HOME} element={<Login />} />
      <Route element={<PrivateRoute />}>
        <Route path={PATHS.DRAW} element={<Draw />} />
        <Route path={PATHS.RESULT} element={<Result />} />
      </Route>
      <Route element={<PrivateAdminRoad />}>
        <Route path={PATHS.Admin} element={<Admin />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace={true} />} />
    </Routes>
  );
};

export default Content;
