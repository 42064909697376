import { Outlet, Navigate } from "react-router-dom";
import { PATHS } from "./Routes";
import AuthenticationService from "../Service/AuthenticationService";

const PrivateAdminRoad = () => {
  const isAuthenticated = AuthenticationService.isAuthenticated;
  const admin = AuthenticationService.admin;
  return isAuthenticated && admin ? <Outlet /> : <Navigate to={PATHS.HOME} />;
};

export default PrivateAdminRoad;
