import { Card } from "primereact/card";
import { Button } from "primereact/button";
import Snowfall from "react-snowfall";
import "./Draw.css";
import { useEffect, useState } from "react";
import img from "../Image/noel.png";
import chemine from "../Image/noel2.png";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../Router/Routes";
import AuthenticationService from "../Service/AuthenticationService";

const Draw = () => {
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (AuthenticationService.tirage !== "Pas de personne tirée")
      navigate(PATHS.RESULT);
  });

  return (
    <div className="App">
      <Snowfall snowflakeCount={350} speed={[0.5, 5]} radius={[0.5, 4]} />
      <div className="app-container-draw">
        <Card
          title="Tirage de Noël"
          subTitle="Prêt pour votre tirage ?"
          className="custom-card z-10"
        >
          <div className="card-content">
            <div className="flex flex-col mb-4">
              <i
                className="pi pi-gift gift-icon"
                style={{
                  fontSize: "50px",
                  color: "#D9B70D",
                  animation: "shake 1.2s ease-in-out infinite",
                }}
              ></i>
            </div>
            <div className="flex items-center justify-center ">
              <Button
                label="C'est parti !"
                className="p-button-outlined p-button-raised w-60 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={async () => {
                  const draw = await AuthenticationService.tirer();
                  if (draw) navigate(PATHS.RESULT);
                }}
              >
                <img
                  src={isHovered ? img : chemine}
                  alt="Père Noël"
                  className="h-10"
                />
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Draw;
