import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { listDrawPersons } from "../Service/ApiService";
import "./Admin.css";
import Snowfall from "react-snowfall";

const Admin: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await listDrawPersons();
      setUsers(data);
    };

    fetchUsers();
  }, []);

  return (
    <>
      <Snowfall snowflakeCount={350} speed={[0.5, 5]} radius={[0.5, 4]} />
      <div className="flex justify-center p-4 app-container-result">
        <Card title="Tirage de noel" className="w-full md:w-8 z-20">
          <DataTable
            value={users}
            paginator
            rows={20}
            scrollable
            scrollHeight="70vh"
          >
            <Column field="utilisateur" header="Nom de l'Utilisateur" />
            <Column field="tirage" header="Personne Tirée" />
          </DataTable>
        </Card>
      </div>
    </>
  );
};

export default Admin;
