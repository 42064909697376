import { login, draw } from "./ApiService";

// Fichier permettant le stockage des informations de l'utilisateur connecté.
export default class AuthenticationService {
  static isAuthenticated: boolean = false;
  static identifiant: string | null = null;
  static token: string | null = null;
  static tirage: any = null;
  static admin: boolean = false;

  static async login(
    email: string,
    password: string,
    remember: boolean
  ): Promise<String> {
    console.log(email);
    var isAuthenticated = false;
    const response = await login(email, password)
      .then((userData: any) => {
        console.log(userData);
        const data = userData.data;
        this.admin = data.admin;
        this.tirage = data.tirage;
        this.identifiant = data.name;
        this.token = data.token;
        isAuthenticated = true;
        return "OK";
      })
      .catch((error: any) => {
        return error.response;
      });
    console.log(response);
    if (response !== "OK")
      return new Promise((resolve) => {
        resolve(response);
      });

    this.isAuthenticated = isAuthenticated;
    localStorage.setItem("token", this.token!);
    localStorage.setItem("identifiant", email);

    return new Promise((resolve) => {
      resolve("OK");
    });
  }

  static async tirer(): Promise<boolean> {
    try {
      const drawnPerson = await draw(this.identifiant!);
      this.tirage = drawnPerson;
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  static testSession(): boolean {
    const rememberCheck = localStorage.getItem("remember");
    if (rememberCheck === "1") {
      return true;
    } else {
      return false;
    }
  }

  static async logout() {
    this.isAuthenticated = false;
    this.identifiant = null;
    this.token = null;
    this.tirage = null;
    this.admin = false;
  }
}
