import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "../App.css";
import backgroundImage from "../Image/test.png";
import logo from "../Image/logo.png";
import Singin from "./Common/Singin";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#1F2440",
  },
  error: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
  },
  center: {
    textAlign: "center",
  },
  wait: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    minWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
  },
  paper: {
    backgroundColor: "rgba(255, 255, 255, .7)",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
  },
  avatar: {},
  form: {
    width: "80%",
  },
  signUp: {
    marginTop: "14px",
  },
  touch: {
    "&:hover": { cursor: "pointer" },
  },
}));

const Login = () => {
  const classes = useStyles();

  return (
    <div className="App">
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        className={classes.wait}
      >
        <Grid item xs={11} sm={10} md={6} lg={5} className={classes.paper}>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "45vh" }}
          >
            <Grid
              item
              xs={12}
              className={classes.signUp}
              style={{ margin: "12px" }}
            >
              <Typography component="h1" variant="h5">
                Connexion
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              md={8}
              className={classes.signUp}
              style={{ margin: "12px", minHeight: "30vh" }}
            >
              <Singin />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                alignContent="center"
                alignItems="center"
                justifyContent="center"
                style={{ backgroundColor: "white" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={3}
                  style={{
                    marginTop: "10px",
                    alignContent: "right",
                    alignItems: "right",
                  }}
                ></Grid>
                <Grid item xs={6} className={classes.signUp}></Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={3}
                  style={{
                    marginTop: "2px",
                    alignContent: "right",
                    alignItems: "right",
                  }}
                >
                  <img
                    src={logo}
                    alt={"Logo HF"}
                    loading="lazy"
                    style={{
                      maxWidth: "80px",
                      maxHeight: "80px",
                      minWidth: "80px",
                      minHeight: "80px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
